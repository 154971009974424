<script setup lang="ts">
import type { ATextProps } from "@netgame/ui/atoms/AText.vue";

import type { Badge } from "@/types";

const props = withDefaults(
	defineProps<{
		badgeData?: Badge | Record<string | number, string | number | boolean | null | undefined>;
		bestDeal?: boolean;
		mostPopular?: boolean;
		textVariant?: ATextProps["variant"];
		size?: number;
		autosize?: boolean;
	}>(),
	{ textVariant: "ternopil" }
);

const badgeBackground = computed(() => {
	if (props.badgeData?.labelBackground) {
		return `${props.badgeData.labelBackground}`;
	}
	if (props?.bestDeal) {
		return "var(--cairns)";
	}
	if (props?.mostPopular) {
		return "var(--genoa)";
	}
	return "var(--caracas)";
});

const badgeColor = computed(() => {
	if (props.badgeData?.labelTextColor) {
		return `${props.badgeData.labelTextColor}`;
	}
	if (props?.bestDeal) {
		return "var(--cannes)";
	}
	if (props?.mostPopular) {
		return "var(--cannes)";
	}
	return "var(--crawley)";
});
</script>

<template>
	<ABadge :background="badgeBackground" variant="info" :size="size" :autosize="autosize">
		<AText :variant="textVariant" :modifiers="['bold', 'uppercase']" :style="`color: ${badgeColor}`">
			{{ badgeData?.percent }}
			{{ badgeData?.text }}
		</AText>
	</ABadge>
</template>

<style scoped lang="scss"></style>
``
